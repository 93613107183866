import * as qiniu from 'qiniu-js'
import dayjs from 'dayjs'
import AMapLoader from '@amap/amap-jsapi-loader'
import Cookies from 'js-cookie'

export function clearCache ({ retain = [], remove = [] }) {
  const obj = Cookies.get()
  for (const item of Object.keys(obj)) {
    if (remove.length === 0) {
      if (!retain.includes(item)) {
        Cookies.remove(item)
      }
    } else {
      if (remove.includes(item)) {
        Cookies.remove(item)
      }
    }
  }
}

export function gdMap () {
  return new Promise((resolve, reject) => {
    if (window.AMap) {
      resolve(window.AMap)
    } else {
      AMapLoader.load({
        key: '8d774427bd396169e10eb789f77e023d',
        version: '2.0',
        plugins: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.ToolBar'],
        AMapUI: {
          version: '1.1',
          plugins: [
            'misc/MobiCityPicker',
            'overlay/SimpleMarker',
            'misc/PositionPicker'
          ]
        }
      }).then(AMap => {
        console.log('首次加载地图')
        resolve(AMap)
      }).catch(e => {
        console.log('加载地图错误')
        console.log(e)
      })
    }
  })
}

export function upload ({ fileBlob, token, domain, name, maxWidth, maxHeight }) {
  console.log(fileBlob)
  return new Promise((resolve, reject) => {
    const n = 1000
    const m = 9999
    const key = name ?? dayjs().format('YYYYMMDDHHmmss') + '_' + parseInt(Math.random() * (m - n + 1) + n)
    const putExtra = {
      fname: fileBlob.name,
      customVars: {},
      mimeType: 'image/jpeg;image/png'
    }
    const config = {}
    // const options = {
    //   quality: 0.92,
    //   noCompressIfLarger: true,
    //   maxWidth: maxWidth || 800
    // }
    var observer = {
      next (res) {
      },
      error (res) {
      },
      complete (res) {
        res.img = domain + res.key
        resolve(res)
      }
    }
    var observable = qiniu.upload(fileBlob, key, token, putExtra, config)
    observable.subscribe(observer)
    // qiniu.compressImage(fileBlob, options).then(data => {
    //   var observable = qiniu.upload(data.dist, key, token, putExtra, config)
    //   observable.subscribe(observer)
    // })
  })
}

export function callWeixinPay (jap) {
  return new Promise((resolve, reject) => {
    if (typeof WeixinJSBridge !== 'undefined') {
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        jap,
        function (res) {
          // window.WeixinJSBridge.log(res.err_msg)
          // alert(res.err_code + res.err_desc + res.err_msg)
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            resolve({
              result: true
            })
          } else {
            resolve({
              result: false
            })
          }
        }
      )
    }
  })
}

export function createUniqueString () {
  const timestamp = new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

export function convertOrderStatusText ({ status }) {
  if (status === 'NOT_PAID') {
    return '待付款'
  }
  if (status === 'PAID') {
    return '已付款'
  }
  if (status === 'WAIT_GROUP') {
    return '待成团'
  }
  if (status === 'WAIT_DELIVER') {
    return '待发货'
  }
  if (status === 'WAIT_USE') {
    return '待使用'
  }
  if (status === 'DELIVERED') {
    return '已发货'
  }
  if (status === 'COMPLETED') {
    return '交易完成'
  }
  if (status === 'REFUNDING') {
    return '退款中'
  }
  if (status === 'REFUNDED') {
    return '已退款'
  }
  if (status === 'NOT_REFUNDED') {
    return '拒绝退款'
  }
  return '未知类型'
}

export function convertGrouponStatusText ({ status }) {
  if (status === 'NOT_STARTED') {
    return '未开始'
  }
  if (status === 'STARTED') {
    return '正在拼团中'
  }
  if (status === 'SUCCESS') {
    return '拼团成功'
  }
  if (status === 'FAIL') {
    return '拼团失败'
  }
  return '未知类型'
}

export function listGrouponStatus () {
  const status = [
    {
      k: 'NOT_STARTED',
      v: '未开始'
    },
    {
      k: 'STARTED',
      v: '正在拼团中'
    },
    {
      k: 'SUCCESS',
      v: '拼团成功'
    },
    {
      k: 'FAIL',
      v: '拼团失败'
    }
  ]
  return status
}

export function listOrderStatus () {
  const status = [
    {
      k: undefined,
      v: '全部'
    },
    // {
    //   k: 'WAIT_GROUP',
    //   v: '待成团'
    // },
    {
      k: 'WAIT_DELIVER',
      v: '待发货',
      b: 'TRANSPORT'
    },
    {
      k: 'DELIVERED',
      v: '已发货'
    },
    {
      k: 'WAIT_USE',
      v: '待使用',
      b: 'BOOKING'
    },
    {
      k: 'COMPLETED',
      v: '交易完成'
    },
    {
      k: 'REFUNDING',
      v: '退款中'
    },
    {
      k: 'REFUNDED',
      v: '已退款'
    },
    {
      k: 'REFUSE_REFUNDED',
      v: '拒退款'
    },
    {
      k: 'EXPIRED',
      v: '已失效'
    }
  ]
  return status
}
